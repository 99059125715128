import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/inkjet/g3115.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
        Inkjet acts in that non definable world of experiments with sound and
        extreme music mainly though intervews with bands and artists who have
        been pushing forward the boundaries of weirdness.
      </p>
      <div className={styles.linkUser}>
        <h3 style={{ marginLeft: "0px", marginBottom: "0px" }}>
          <a href="mailto:info@codafanzine.net">CONTACT</a>
        </h3>
        <h3 style={{ marginLeft: "40px", marginBottom: "0px" }}>
          <a
            href="https://codafanzine.bandcamp.com/album/trauma-lever"
            target="_blank" rel="noreferrer">SHOP</a>
        </h3>
      </div>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/inkjet/inkjet_foto.png"
        className={styles.imageUser}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div className={styles.review}>
    <div className={styles.logodesc}>
      <img
        src={props.logo}
        className={styles.logo}
        alt=""
      />
      <div style={{ textAlign: "center" }} className={styles.description}>
        <p style={{ float: "right" }} className={styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
    <div>
      <a href={props.link} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
        <img src={props.image} className={styles.image} alt="" />
      </a>
    </div>
  </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allInkjetJson.edges.map(({ node }) => (
        <Post
          logo={node.logo}
          excerpt={node.excerpt}
          image={node.image}
          link={node.link}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allInkjetJson {
      edges {
        node {
          logo
          excerpt
          image
          link
        }
      }
    }
  }
`;
